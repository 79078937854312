import "./App.css";
import img1 from "./assets/img/img1.jpeg";
import tos from "./assets/img/tos_pool.png";
import conch from "./assets/img/conch.png";
import img2 from "./assets/img/img2.jpeg";
import img3 from "./assets/img/img3.jpeg";
import map from "./assets/img/map.jpeg";
import pink_sands2 from "./assets/img/pink_sands2.avif";
import welcome_party from "./assets/img/welcome_party.jpg";
import tos2 from "./assets/img/tos2.jpg";
import bbq from "./assets/img/bbq.jpeg";
import { useMediaQuery } from "react-responsive";

function App() {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <div className="main">
      <header className="home singlePage header coverpage">
        <h1 className="monogram text">CHRISTINA & ZACH</h1>
      </header>
      <section className="section-one" style={{ marginTop: 84 }}>
        <h1
          style={{
            color: "#000000",
            letterSpacing: 0,
          }}
        >
          <span>
            CHRISTINA
            <br />
            AND
            <br />
            ZACH
          </span>
        </h1>
        <div className="bgbox">
          <img src={pink_sands2} alt="image1" />
        </div>
      </section>
      <div className="section section-two">
        <div className="text-container">
          <h3> JUNE 6TH, 2025 </h3>
          <br />

          <h2>CHRISTINA & ZACH'S WEDDING</h2>
          <div className="subsection">
            <h3 style={{ color: "#000000" }}>
              THE OTHER SIDE
              <br />
              ELEUTHERA, BAHAMAS
            </h3>
            <h4 style={{ color: "#000000" }}>
              <br />
              *Formal Invites to Follow*
            </h4>
          </div>
        </div>
      </div>
      <div className="section section-three split">
        {isMobile ? (
          <>
            <div className="section-container">
              <div className="img-container">
                <img src={welcome_party} alt="image2" />
              </div>
            </div>
            <div className="section-container">
              <div className="text-container">
                <h2>WELCOME COCKTAIL PARTY</h2>
                <div className="subsection">
                  <h3>
                    THURSDAY, JUNE 5TH, 2025
                    <br />
                    5:30PM - 7:30PM
                  </h3>
                  <h4>
                    <br />
                    LOCATION TBD
                  </h4>
                  <p>
                    We are excited to celebrate with you. Please join us in a
                    <span style={{ fontWeight: 800 }}>"white themed"</span>{" "}
                    attire for the welcome cocktail party.
                    <br />
                    <br />
                    *RSVP in formal invitation*
                  </p>
                </div>
              </div>
            </div>

            <div className="section-container">
              <div className="img-container">
                <img src={tos} alt="image3" />
              </div>
            </div>
            <div className="section-container">
              <div className="text-container">
                <h2>WEDDING CEREMONY & RECEPTION</h2>
                <div className="subsection">
                  <h3>
                    FRIDAY, JUNE 6TH, 2025
                    <br />
                    CEREMONY STARTS AT 5:00PM
                  </h3>
                  <h4>
                    <br />
                    THE OTHER SIDE, ELEUTHERA, BAHAMAS
                  </h4>
                  <p>
                    Boat transportation will be provided from Harbour Island to
                    The Other Side. Please arrive at the dock by 4:00pm for a
                    5:00pm ceremony start time. The ceremony will be followed by
                    a cocktail hour and reception. We will be providing
                    transportation back to Harbour Island after the reception.
                    <br />
                    <br />
                    Ferry service will be provided from the following locations:
                    The Government Docks, Valentine's Resort & Marina and Romora
                    Bay Hotel & Resort.
                    <br />
                    <br />
                    *RSVP in formal invitation*
                  </p>
                </div>
              </div>
            </div>
            <div className="section-container">
              <div className="img-container">
                <img src={bbq} alt="image3" />
              </div>
            </div>
            <div className="section-container">
              <div className="text-container">
                <h2>THE DAY AFTER</h2>
                <div className="subsection">
                  <h3>
                    SATURDAY, JUNE 7TH, 2025
                    <br />
                    12:00PM - 3:00PM
                  </h3>
                  <h4>
                    <br />
                    THE OTHER SIDE, ELEUTHERA, BAHAMAS
                  </h4>
                  <p>
                    Come relax with us for a poolside party & BBQ. Have a drink
                    or two to kick the hangover! Please also feel free to use
                    this time to explore and enjoy what the island has to offer.
                    {/*           
                We will
                be relaxing on the beach at The Other Side the day after the
                wedding with a little pool party BBQ; " "} */}
                    <br />
                    <br />
                    *RSVP in formal invitation*
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="section-container">
              <div className="img-container">
                <img src={welcome_party} alt="image2" />
              </div>
            </div>
            <div className="section-container">
              <div className="text-container">
                <h2>WELCOME COCKTAIL PARTY</h2>
                <div className="subsection">
                  <h3>
                    THURSDAY, JUNE 5TH, 2025
                    <br />
                    5:30PM - 7:30PM
                  </h3>
                  <h4>
                    <br />
                    LOCATION TBD
                  </h4>
                  <p>
                    We are excited to celebrate with you. Please join us in a
                    "white themed" attire for the welcome cocktail party.
                    <br />
                    <br />
                    *RSVP in formal invitation*
                  </p>
                </div>
              </div>
            </div>
            <div className="section-container">
              <div className="text-container">
                <h2>WEDDING CEREMONY & RECEPTION</h2>
                <div className="subsection">
                  <h3>
                    FRIDAY, JUNE 6TH, 2025
                    <br />
                    CEREMONY STARTS AT 5:00PM
                  </h3>
                  <h4>
                    <br />
                    THE OTHER SIDE, ELEUTHERA, BAHAMAS
                  </h4>
                  <p>
                    Boat transportation will be provided from Harbour Island to
                    The Other Side. Please arrive at the dock by 4:00pm for a
                    5:00pm ceremony start time. The ceremony will be followed by
                    a cocktail hour and reception. We will be providing
                    transportation back to Harbour Island after the reception.
                    <br />
                    <br />
                    Ferry service will be provided from the following locations:
                    The Government Docks, Valentine's Resort & Marina and Romora
                    Bay Hotel & Resort.
                    <br />
                    <br />
                    *RSVP in formal invitation*
                  </p>
                </div>
              </div>
            </div>
            <div className="section-container">
              <div className="img-container">
                <img src={tos} alt="image3" />
              </div>
            </div>
            <div className="section-container">
              <div className="img-container">
                <img src={bbq} alt="image3" />
              </div>
            </div>
            <div className="section-container">
              <div className="text-container">
                <h2>THE DAY AFTER</h2>
                <div className="subsection">
                  <h3>
                    SATURDAY, JUNE 7TH, 2025
                    <br />
                    12:00PM - 3:00PM
                  </h3>
                  <h4>
                    <br />
                    THE OTHER SIDE, ELEUTHERA, BAHAMAS
                  </h4>
                  <p>
                    Come relax with us for a poolside party & BBQ. Have a drink
                    or two to kick the hangover! Please also feel free to use
                    this time to explore and enjoy what the island has to offer.
                    {/*           
                We will
                be relaxing on the beach at The Other Side the day after the
                wedding with a little pool party BBQ; " "} */}
                    <br />
                    <br />
                    *RSVP in formal invitation*
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="section section-four">
        <div
          className="section-card"
          style={{
            backgroundColor: "rgb(255, 231, 226)",
          }}
        >
          <div className="subsection">
            <h2>GETTING THERE</h2>
            <h3>FLYING INTO NORTH ELEUTHERA - ELH (RECOMMENDED)</h3>
            <h4>
              North Eleuthera is a large island that is located adjacent to
              Harbour Island. Flying into Eleuthera is the easiest way to get to
              Harbour Island, and what we recommend booking for your travel.
              Make sure your passports are current! Many countries require
              passports to be valid for 6 months beyond your travel as well. We
              recommended carrying some smaller US bills/cash for the quick
              taxi/boat taxi fares. Once you land, you'll take a taxi to Three
              Islands Dock. The taxi from the airport to Three Islands Dock is
              $10 per person per way. The Water Taxi to Harbour Island is $5 per
              person per way.
              <br />
              <br />
              Airlines offering direct service to North Eleuthera (ELH) include:
              Silver Airways from Ft Lauderdale (FLL) or Orlando (MCO), American
              Airlines from Miami (MIA) or Charlotte (CLT), Delta Airlines from
              Atlanta (ATL), and Pineapple or Southern Air from Nassau (NAS).
              There may be a few other direct flights added this year as well.
              If you are not flying out of one of the direct airports, you
              should be able to find a one-stop flight itinerary through one of
              them to get to the island. We recommend using Kayak.com or
              Google.com to search multiple airlines from your departure.
              <br />
              <br />
              YOUR TRAVEL FROM THE AIRPORT TO YOUR LODGING WILL DEPEND ON WHICH
              HOTEL YOU ARE STAYING AT. PLEASE REFER TO THE APPROPRIATE
              DIRECTIONS BASED ON YOUR HOTEL!
            </h4>
            <h3>GETTING TO THE OCEAN VIEW CLUB / HARBOUR ISLAND</h3>
            <h4>
              Once you arrive at the North Eleuthera Airport, exit the plane,
              collect your luggage, and walk outside of the tiny airport to the
              street. You will need to take a 5-minute taxi to the "Three Island
              Dock", another 5-minute water taxi to Harbour Island’s Government
              Dock, and finally a 2-minute taxi to the hotel. If you'd prefer a
              private transfer for each leg of this journey, the hotels
              concierge team can assist and will reach out after booking. The
              island is only 3 miles long and ½ mile wide so wherever you are
              staying should only be only a few minutes away.
            </h4>
            <h3>GETTING TO THE OTHER SIDE OR THE FARM</h3>
            <h4>
              Once you arrive at the North Eleuthera Airport, exit the plane,
              collect your luggage, and walk outside the tiny airport to the
              street. There will be taxis waiting to take you on a short
              5-minute ride to the "Three Island Dock". The hotel's Tender and
              Captain will greet you and take you on the 8-minute boat ride to
              the Other Side/Farm hotels. Alternatively (and in adverse weather
              or if you have an aversion to boats...) you can take a 15-minute
              land taxi to the facility. Again, if you'd prefer a private
              transfer for each leg of this journey, the hotels concierge team
              can assist and will reach out after booking.
            </h4>
          </div>
        </div>
      </div>
      <div className="section section-five">
        <div className="img-container">
          <img src={map} alt="map" />
        </div>
      </div>
      <div className="section section-six">
        <div
          className="section-card"
          style={{
            backgroundColor: "#FEFFED",
          }}
        >
          <div className="subsection">
            <h2>WHERE TO STAY</h2>
            <h4>
              We highly recommend to book ASAP because the island is very small
              and books up quickly.
              {/* <br />
              <br />
              Please note that we have fully booked The Ocean View Club and The
              Other Side for family members and friends. Both properties are
              fully reserved with wedding guests and are either a stone's throw
              away or directly next door to all other listed properties. */}
            </h4>
            <h3 style={{ fontSize: 15 }}>
              Please note that some rooms might have a wedding discount, so
              mention the Lewis/Winters wedding when making reservations.{" "}
            </h3>
            <div className="line"></div>
            <h3>CORAL SANDS HOTEL</h3>
            <h4>Location: Harbour Island</h4>
            <div className="button">
              <a href="https://coralsands.com/" target="_blank">
                BOOK A ROOM
              </a>
            </div>
            <h3>THE DUNMORE</h3>
            <h4>Location: Harbour Island</h4>
            <div className="button">
              <a href="https://www.dunmorebeach.com/" target="_blank">
                BOOK A ROOM
              </a>
            </div>
            <h3>VALENTINES RESORT & MARINA</h3>
            <h4>Location: Harbour Island</h4>
            <div className="button">
              <a href="https://us01.iqwebbook.com/VRM922/" target="_blank">
                BOOK A ROOM
              </a>
            </div>
            <h3>ROMORA BAY RESORT & MARINA</h3>
            <h4>Location: Harbour Island</h4>
            <div className="button">
              <a
                href="https://www.romorabay.com/accommodations.htm"
                target="_blank"
              >
                BOOK A ROOM
              </a>
            </div>
            <h3>THE LANDING HOTEL</h3>
            <h4>Location: Harbour Island</h4>
            <div className="button">
              <a href="https://harbourislandlanding.com/" target="_blank">
                BOOK A ROOM
              </a>
            </div>
            <h3>THE ROCK HOUSE HOTEL</h3>
            <h4>Location: Harbour Island</h4>
            <div className="button">
              <a href="https://www.rockhousebahamas.com/" target="_blank">
                BOOK A ROOM
              </a>
            </div>
            <h3>PINK SANDS RESORT</h3>
            <h4>Location: Harbour Island</h4>
            <div className="button">
              <a href="https://www.pinksandsresort.com/" target="_blank">
                BOOK A ROOM
              </a>
            </div>
            <h3>TINGUM VILLAGE HOTEL</h3>
            <h4>Location: Harbour Island</h4>
            <div className="button">
              <a href="https://tingumvillage.com/" target="_blank">
                BOOK A ROOM
              </a>
            </div>
            <div className="line"></div>
            <h2>HOUSE RENTALS</h2>
            <h4>
              If you want to stay with a group while on Harbour Island, we
              suggest looking at AirBnb or VRBO rentals. Rentals book up very
              quickly, we recommend booking ASAP.
              <br />
              <br />
              There is a gentleman named Aldon Davis on island that offers a
              concierge service for any above and beyond accommodations. He
              provides services that are tailored to individual preferences and
              needs, such as stocking your fridge, private chefs, booking
              excursions, etc. These services are available at an additional
              cost.
              <br />
              <h3 style={{ fontSize: 15, wordSpacing: 1 }}>
                Concierge Service available for all your special needs.
                <br />
                Call Aldon Davis at +1 (242)-470-8116.
              </h3>
            </h4>
          </div>
        </div>
      </div>
      <div className="section section-seven">
        <div
          className="section-card"
          style={{
            backgroundColor: "#e6faff",
          }}
        >
          <div className="subsection">
            <h2>GETTING AROUND</h2>
            <h3>GOLF CARTS!</h3>
            <h4>
              Golf carts are the primary mode of transportation once you’re on
              Harbour Island; they are a blast to cruise around in and a great
              way to explore the island! We would recommend renting your own
              cart to get around the island during your stay.
              <br />
              <br />
              PEOPLE DO DRIVE ON THE OPPOSITE SIDE OF THE ROAD ON THE ISLAND,
              JUST A HEADS UP!
              <br />
              <h3 style={{ fontSize: 15, letterSpacing: 1 }}>
                {" "}
                We have already pre-booked a number of golf carts at: BRILAND ON
                THE GO.
              </h3>
              Please call <span style={{ fontWeight: 600 }}>
                Marcus Pinder
              </span>{" "}
              for your wedding discount.
              <br />
              Phone Number: 242-333-2222;
              {/* <br />
              <br />
              If you would like to arrange your own rental, we would recommend
              the following companies: */}
            </h4>
            {/* <div className="buttons">
              <div className="button">
                <a
                  href="https://www.conchandcoconut.com/products/harbour-island-golf-cart-rentals"
                  target="_blank"
                >
                  CONCH AND COCONUT
                </a>
              </div>
              <div className="button">
                <a href="https://www.majorsgolfcartrental.com/" target="_blank">
                  MAJOR'S RENTALS
                </a>
              </div>
              <div className="button">
                <a href="https://lulusgolfcartrentals.com/" target="_blank">
                  LULU'S RENTALS
                </a>
              </div>
            </div> */}
            {/* <div className="buttons">
              <div className="button">
                <a href="https://dunmorerentals.com/" target="_blank">
                  DUNMORE RENTALS
                </a>
              </div>
              <div className="button">
                <a
                  href="https://www.harbourislandguide.com/golf-cart-rentals.htm"
                  target="_blank"
                >
                  JOHNSON'S RENTALS
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="section section-eight">
        <div
          className="section-card"
          style={{
            backgroundColor: "#e6ffe6",
          }}
        >
          <div className="subsection">
            <h2>THINGS TO DO</h2>
            <h3>VISIT PINK SANDS BEACH!</h3>
            <h4>
              Harbour Island, or "Briland" as the locals say, is most renowned
              for its beautiful pink sand beach, stretching for three plus
              miles. You will also hear Harbour Island referred to as "Dunmore
              Town". There are restaurants, massages on the beach, hotels, and
              some tiki bars as you walk that side of the island. Check out Ms.
              V's if you feel like trying the local Goombay Smash or sip on a
              Pina Colada. You can also snorkel right off the beach on this side
              of the island, and you can see plenty of reefs, fish, sting rays,
              lobsters, starfish etc! You may bring your own snorkeling
              equipment for this, but you can also rent it through the hotels.
              <br />
              <br />
            </h4>
            <h3>BOAT EXCURSIONS</h3>
            <h4>
              There are many companies that will take you on a half or full day
              excursion around the area that you can find near the dock at the
              Valentines Resort & Marina or at Romora Bay Marina. Popular
              activities include snorkeling, scuba, seeing the sea turtles,
              visiting the Spanish Wells, Sapphire Hole, the sandbar, swimming
              with the pigs, and exploring Preacher's Cove.
              <br />
              <h3 style={{ fontSize: 15, letterSpacing: 1 }}>
                {" "}
                When booking, please let them know that you're with the
                Lewis/Winters wedding for your discounted rate.
              </h3>
              <br />
              Water Fantaseas: Marquis Pinder +1 (242) 551-1679
              <br />
              Duke Davis Boat Rentals: +1 (242) 359-7952
              <br />
              <br />
            </h4>
            <h3>DIVING & SNORKELING</h3>
            <h4>
              Visit the Dive Shop at Valentines Marina.
              <br />
              <br />
              www.valentiesdive.com
              <br />
              <br />
            </h4>
            <h3>BOAT RENTALS, JET SKI RENTALS, SNORKELING AND WAKE BOARDING</h3>
            <h4>
              Ask for Lil Shaun's located beside the Valentines Resort
              <br />
              <br />
              www.lswatersports.com
              <br />
              <br />
            </h4>
            <h3>DEEP SEA FISHING</h3>
            <h4>
              The area has some fantastic fishing. There are multiple companies
              that you can find near Valentines Resort & Marina or at Romora Bay
              Marina that can tailor a fishing experience to what you are
              looking for. There are also people we have seen bone-fishing in
              the shallows off of the West side of the island without the need
              for a boat rental/guide.
              <br />
              <br />
              Captain Fox - Romora Bay Marina - foxfishingbahamas.com
              <br />
              Devon Sawyer - +1 (242) 359-7600
              <br />
              <br />
            </h4>
            <h3>BONE FISHING</h3>
            <h4>
              Bonefish Stuart is an excellent guide for local bone fishing.
              <br />
              <br />
              +1 (242) 551-1227
              <br />
              <br />
            </h4>
            <h3>HORSEBACK RIDING</h3>
            <h4>
              Imagine riding your horse in the crashing waves of the Atlantic
              Ocean; galloping along the beach with a serene sunset in the
              background. This is truly a once in a lifetime experience. If you
              are a novice rider, you must follow all the instructions given by
              the guide. You will be taught to handle and ride the horse with a
              collective guided group.
              <br />
              <br />
              Marty or Bryon Cous: +1 (242) 359-7759
              <br />
              <br />
            </h4>
            <h3>SWIMMING PIGS</h3>
            <h4>
              Pig Beach Bahamas Tours <br />
              <br />
              +1 (242) 422-9999
              <br />
              <br />
              getsaltywet@outlook.com - www.dasaltypig.com
              <br />
              <br />
            </h4>
            <h3>SHOPPING</h3>
            <h4>
              For such a small island, Harbour Island offers an abundance of
              retail therapy. Walk through "Dunmore Town" and you will find a
              number of cute boutiques, galleries, and local shops. There is a
              local corner store with groceries and snacks called "Pigly Wigley"
              and also a few liquor stores in town.
              <br />
              <br />
              There are a variety of shops to get some local Bahamian trinkets.
              These include Dilly Dally, The Blue Rooster, Dake's Shoppe, Coral
              Sands Gift Shop, W Sail Bags, Island Treasures, Annie's
              Souveniers, The Sugar Mill and Miss Mae's Fine things, and A and A
              Hidden Treasures.
              <br />
              <br />
            </h4>
          </div>
        </div>
      </div>
      <div className="section section-nine">
        <div
          className="section-card"
          style={{
            backgroundColor: "#ebf0fa",
          }}
        >
          <div className="subsection">
            <h2>BREAKFAST / LUNCH</h2>
            <h3>SWEET SPOT CAFE</h3>
            <h4>
              Fresh made-to-order smoothies, cold pressed juices, healthy
              snacks, acai bowls etc. Vegan breakfast and lunch. This place was
              a morning staple for us for a nice fresh detox!
            </h4>
            <div className="button">
              <a href="https://www.sweetspotcafe.net/" target="_blank">
                SWEET SPOT
              </a>
            </div>

            <h3>BAHAMAS COFFEE ROASTERS</h3>
            <h4>
              A central location by Governer's dock to get grab-and-go items.
              They have good coffee, fresh coconut water, and lunch sandwiches
              to take on the go.
            </h4>
            <div className="button">
              <a href="https://www.bahamascoffeeroasters.com/" target="_blank">
                BAHAMAS COFFEE
              </a>
            </div>
            <h3>BLUWATUR BAKERY</h3>
            <h4>
              Best spot for morning coffee, pastries, or baked goods/cakes with
              nice cozy couches and books. You have to get there early; they
              sell out quickly!
            </h4>
            <div className="button">
              <a href="https://www.bluwatur.com/" target="_blank">
                BLUWATUR
              </a>
            </div>
            <h3>ARTHUR'S BAKERY</h3>
            <h4>
              Small local spot for breakfast and lunch. They also have a
              bakery/breads/pastries. Stickiest sticky buns ever, boozy rum
              cake, and their "famous" raisin bread.
            </h4>
            <div className="button">
              <a
                href="https://www.facebook.com/profile.php?id=100063728615705"
                target="_blank"
              >
                ARTHUR'S
              </a>
            </div>
            <h3>COCOA COFFEE HOUSE</h3>
            <h4>
              A good spot for a Frappuccino on the go, we all know Zach loves a
              good "Frapp". This place is closer to Valentines Marina area and
              looks like a little yellow house.
            </h4>
            <div className="button">
              <a href="https://www.cocoacoffeehouse.com/" target="_blank">
                COCOA COFFEE
              </a>
            </div>
            <h3>BLUE BAR & RESTAURANT</h3>
            <h4>
              Located at the Pink Sands Hotel, walk right up from the beach for
              casual breakfast or lunch. Great conch ceviche here as well!
            </h4>
            <div className="button">
              <a href="https://www.pinksandsresort.com/dining/" target="_blank">
                BLUE BAR & RESTAURANT
              </a>
            </div>
            <h3>MARTY'S KING CONCH</h3>
            <h4>
              Located just steps away from The Government Dock, King Conch and
              the perfect location to watch the sunset offers fresh Conch Salad,
              sliced or diced.
            </h4>
            <h3>QUEEN CONCH</h3>
            <h4>
              Casual Bahamian seafood snack bar on Bay Street, locally known for
              conch salad, soup, and fritters!
            </h4>
            <div className="button">
              <a
                href="https://www.tripadvisor.com/Restaurant_Review-g148423-d2085249-Reviews-Queen_Conch-Harbour_Island_Out_Islands_Bahamas.html"
                target="_blank"
              >
                QUEEN CONCH
              </a>
            </div>
            <h3>THE LANDING RESTAURANT</h3>
            <h4>
              THE spot for Sunday Brunch!
              <br /> Reservation Recommended
            </h4>
            <div className="button">
              <a
                href="https://harbourislandlanding.com/the-landing-restaurant/"
                target="_blank"
              >
                THE LANDING
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-nine">
        <div
          className="section-card"
          style={{
            backgroundColor: "#EBF0F0",
          }}
        >
          <div className="subsection">
            <h2>DINNER</h2>
            <h3>ROCK HOUSE</h3>
            <h4>
              One of our favorite patio dinner spots with live music and a
              perfect view for the sunset, they also have lunch. This is the
              restaurant at the Rock House Hotel.
              <br />
              Reservation Recommended
            </h4>
            <div className="button">
              <a
                href="https://www.rockhousebahamas.com/harbour-island-bahamas-restaurants"
                target="_blank"
              >
                ROCK HOUSE
              </a>
            </div>
            <h3>THE LANDING RESTAURANT</h3>
            <h4>
              Elevated old world eats, upscale, but probably our favorite dinner
              we had on the island. Located at the Landing Hotel, so it is a
              great spot to grab dinner on the patio around sunset. Hands down
              the best lobster we had on the island!
              <br /> Reservation Recommended
            </h4>
            <div className="button">
              <a
                href="https://harbourislandlanding.com/the-landing-restaurant/"
                target="_blank"
              >
                THE LANDING
              </a>
            </div>

            <h3>THE DUNMORE</h3>
            <h4>
              Located at the Dunmore Hotel; upscale dining, has fabulous lunch,
              drinks, and dinner. Get the tuna poke bowl, fresh ceviche, and
              fresh stone crab. Highly recommended!
              <br /> Reservation Recommended
            </h4>
            <div className="button">
              <a
                href="https://www.dunmorebeach.com/the-restaurant"
                target="_blank"
              >
                THE DUNMORE RESTAURANT
              </a>
            </div>
            <h3>DA VINE WINE AND SUSHI BAR</h3>
            <h4>
              A perfect place for some light-sushi at sunset on the patio from a
              former Nobu chef. <br /> Reservation Recommended
            </h4>
            <div className="button">
              <a href="https://www.davinewine.com/" target="_blank">
                DA VINE
              </a>
            </div>
            <h3>THE OCEAN VIEW CLUB RESTAURANT</h3>
            <h4>
              Located at the Ocean View Club Hotel. Reservation Recommended
            </h4>
            <div className="button">
              <a
                href="https://www.ilovetheoceanview.com/the-restaurant"
                target="_blank"
              >
                THE OCEAN VIEW CLUB
              </a>
            </div>
            <h3>MALCOLM 51</h3>
            <h4>
              Located at the Pink Sands Resort, Malcolm 51 is a great spot for
              fine dining. Food that makes you feel good. Island-inspired and
              always with an eye on wellness and flavor.
              <br /> Reservation Recommended
            </h4>
            <div className="button">
              <a href="https://www.pinksandsresort.com/dining/" target="_blank">
                MALCOLM 51
              </a>
            </div>
            <h3>VALENTINES RESORT & MARINA</h3>
            <h4>
              Enjoy bistro favorites, elegant seafood, and inventive cocktails.
              Rooster’s entire menu is also available for room service to
              experience the ambience at the table or in your villa. Experience
              what the best restaurant experience on Harbour Island has to
              offer.
              <br /> Reservation Recommended
            </h4>
            <div className="button">
              <a href="https://www.valentinesresort.com/dine/" target="_blank">
                VALENTINES
              </a>
            </div>
            <h3>LATITUDE 25</h3>
            <h4>
              Located at Coral Sands, Latitude 25 offers all-day indulgences to
              sate any sized appetites. Enjoy seaside classics at our Italian
              bistro with uniquely Bahamian undertones and seasonal just-caught
              delicacies.
              <br /> Reservation Recommended
            </h4>
            <div className="button">
              <a href="tel:+18885685493" target="_blank">
                LATITUDE 25
              </a>
            </div>
            <h3>ACQUAPAZZA</h3>
            <h4>
              Italian restaurant for local seafood and pasta.
              <br /> Reservation Recommended
            </h4>
          </div>
        </div>
      </div>
      <div className="section section-nine">
        <div
          className="section section-card"
          style={{
            backgroundColor: "#DBD5D9",
          }}
        >
          <div className="subsection">
            <h2>LATE NIGHT</h2>
            <h3>VICHUM CLUB</h3>
            <h4>
              Best nightlife on the island, located right in the center of town.
              Live band Wednesday night!
              <br />
              Started in 1955 by The Percentie Brothers - Victor & Humphrey. In
              December 2023, a legacy was reborn by the grandsons - Jay Jay &
              Elvis.
            </h4>

            <h3>ELBOW ROOM</h3>
            <h4>
              Fun cocktail bar to stop by before or after dinner; located right
              by Governer's Dock in Harbour Island near all the restaurants.
            </h4>

            <h3>GUSTY'S</h3>
            <h4>
              From Bay Street, the party moves uphill to Gusty's with sand on
              the floor, a DJ, outdoor seating, and a pool table to close out
              the night.
            </h4>

            <h3>DADDY D'S</h3>
            <h4>
              Late night dance club with a DJ located right in the center of
              town.
            </h4>
          </div>
        </div>
      </div>
      <div className="section section-nine">
        <div
          className="section section-card"
          style={{
            backgroundColor: "rgb(255, 231, 226)",
          }}
        >
          <div className="subsection">
            <h2>JUST IN CASE</h2>
            <h3>CASH & SUPPLIES</h3>
            <h4>
              A cash machine or ATM can be found at the RBC branch at the topf
              of Dunmore Street.
              <br />
              <br />
              Suncreen can be found at the Tip Top Store, The Valentines Dive
              Shop & the Piggly Wiggly Supermarket.
            </h4>
            <h3>EMERGENCIES</h3>
            <h4>
              Accidents do happen! If you do need emergency contacts:
              <br />
              <br />
              The Clinic - +1 (242) 333 2227
              <br />
              <br />
              Harbour Island Police Station - +1 (242) 333 2111
            </h4>
          </div>
        </div>
      </div>
      <div className="section section-nine">
        <div className="section-card">
          <div className="subsection">
            <h2>
              {" "}
              Looking forward to having the best time with family and friends!
            </h2>
            <h4>
              For any questions, please reach out to{" "}
              <a href="mailto:christinaandzach2025@gmail.com">
                christinaandzach2025@gmail.com
              </a>
            </h4>
            <br />
            <br />
            <img src={conch} style={{ height: 225 }} alt="image3" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
